import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import { NavLink } from 'react-router-dom'
import About from './About'
import Projects from './Projects'
import Contact from './Contact'
import Fotter from './Fotter'
import Foot from './Foot'
import RotateMan from './icons/RotateMan'
import github from '../assets/github.svg'
import linkedin from '../assets/linkedin.svg'

const Home = () => {
  return (
    <>
      <section className="container-fluid bg-dark pt-5 min-vh-100">
        <div className="container-fluid">
          <div className="d-sm-flex align-items-center justify-content-center bg-dark">
            <div className="d-flex flex-column text-center align-items-center align-items-center">
              <h1 className="custom-font">Hi, I am Ali </h1>
              <h1 className="fw-bold custom-font">Frontend Developer</h1>
              <div className="bg-dark m-2">
                <a href="https://github.com/AliIbrahimCesur">
                  <img
                    src={github}
                    alt="logo"
                    width="30"
                    height="auto"
                    className="d-inline-block align-text-top m-2"
                  />
                </a>
                <a href="https://www.linkedin.com/in/alicesur-94/">
                  <img
                    src={linkedin}
                    alt="logo"
                    width="30"
                    height="auto"
                    className="d-inline-block align-text-top  m-2"
                  />
                </a>
              </div>
              <p className="pt-5">Software Development</p>
              <NavLink to="/projects">
                <button
                  className="btn btn-primary custom-btn ps-5 pe-5 fw-bold mt-2"
                  onClick={() => console.log('cl')}
                >
                  My Projects
                </button>
              </NavLink>
            </div>
          </div>
          <About />
          <Contact />
          <Fotter />
        </div>
      </section>
    </>
  )
}

export default Home
