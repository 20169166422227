import RestCountries from './assets/portfolioImages/restcountries.jpg'
import SpaceTourism from './assets/portfolioImages/spacetourism.jpg'
import Advice from './assets/portfolioImages/advise.png'

import AWS from './assets/portfolioImages/s3.jpg'
import Todo from './assets/portfolioImages/todo.jpg'
import Chat_me from './assets/portfolioImages/Chat_me.png'
import Messmitra from './assets/portfolioImages/Mess.png'
import song from './assets/portfolioImages/song.png'
import e1 from './assets/eduapp/e1.jpeg'
import e2 from './assets/eduapp/e2.jpeg'
import e3 from './assets/eduapp/e3.jpeg'
import e4 from './assets/eduapp/e4.jpeg'
import c1 from './assets/crossword/c1.jpeg'
import c2 from './assets/crossword/c2.jpeg'
import c3 from './assets/crossword/c3.jpeg'
import h1 from './assets/horoscope/h1.jpeg'
import h2 from './assets/horoscope/h2.jpeg'
import h3 from './assets/horoscope/h3.jpeg'

const ProjectsData = [
  {
    id: 'rest-countries',
    img: [c1, c2, c3],
    name: 'Bi CrossWords',
    stack: ['< Html/>', '< Css />', '< Js />', '</React Native>'],
    live: 'https://play.google.com/store/apps/details?id=com.crossword.bicrosswords&hl=tr&gl=TR',
    source: 'https://github.com/Siddhesh7777/bluebit',
    description:
      'A user-friendly crossword mobile app that helps improve English vocabulary for people who know Turkish. This app presents a Turkish word, and you must find and fill in the corresponding English word in the boxes. This mobile app is available on the Play Store. You can download it from the following link :)',
  },
  {
    id: 'rest-countries',
    img: [e1, e2, e3, e4],
    name: 'Edu App',
    stack: ['< Html/>', '< Css />', '< Js />', '</React Native>'],

    live: '',
    source: '',
    description:
      'This mobile app is designed to help improve your English proficiency through games. The application offers you a wide range of words from different categories, and you can choose to either add or remove them. If you select the remove option, you will not see that word again. If you choose the add section, you can learn those words through four different games. This application will be published soon.',
  },
  {
    id: 'rest-countries',
    img: [h1, h2, h3],
    name: 'Horoscope App',
    stack: ['< Html/>', '< Css />', '< Js />', '</React Native>'],

    live: '',
    source: '',
    description:
      'This mobile app provides daily horoscope readings and comments tailored to you. In this entertaining app, you can also explore the compatibility of two horoscopes. Get ready to discover your daily horoscope predictions and uncover the secrets of zodiac compatibility.',
  },
]

export default { ProjectsData }
