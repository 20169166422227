import React from 'react'
import './Footer.css'
import { FaBootstrap } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import leetcode from '../assets/leetcode.png'
import github from '../assets/github.svg'
import linkedin from '../assets/linkedin.svg'

export default function Fotter() {
  return (
    <div>
      <div className="bg-dark m-2">
        <a href="https://github.com/AliIbrahimCesur">
          <img
            src={github}
            alt="logo"
            width="30"
            height="auto"
            className="d-inline-block align-text-top m-2"
          />
        </a>
        <a href="https://www.linkedin.com/in/alicesur-94/">
          <img
            src={linkedin}
            alt="logo"
            width="30"
            height="auto"
            className="d-inline-block align-text-top  m-2"
          />
        </a>
      </div>
    </div>
  )
}
