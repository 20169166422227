import React from 'react'
import data from '../data.js'

import Project from './Project.js'
import './Projects.css'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import s23 from './s23.png'
import ima_ from '../assets/portfolioImages/intropage.jpg'
import RotateMan from './icons/RotateMan.js'
import github from '../assets/github.svg'
import linkedin from '../assets/linkedin.svg'
import googleplay from '../assets/googleplay.svg'

import cssicon from '../assets/css3.svg'
import pyicon from '../assets/python-svgrepo-com.svg'
import javascripticon from '../assets/javascript.svg'
import reacticon from '../assets/react.svg'

const Projects = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <div className="content bg-dark pt-5 h-auto">
      <div className="container">
        {data.ProjectsData.map((item) => (
          <div key={item.id} className="imgContainer">
            <h2 className="title">{item.name}</h2>
            <Slider {...settings}>
              {item.img.map((value) => (
                <div key={item.id} className="containerImg">
                  <img src={value} alt={value} className="img" />
                </div>
              ))}
            </Slider>
            <p className="description">{item.description}</p>
            <div
              style={{
                color: 'whitesmoke',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                justifySelf: 'center',
                textAlign: 'center',
                height: 'auto',
                display: 'flex',
              }}
            >
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg"
                alt="logo"
                width="30"
                height="auto"
                className="d-inline-block align-text-top m-2"
              />
              <img
                src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg"
                alt="logo"
                width="30"
                height="auto"
                className="d-inline-block align-text-top m-2"
              />
              <img
                src={pyicon}
                alt="logo"
                width="30"
                height="auto"
                className="d-inline-block align-text-top m-2"
              />
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original-wordmark.svg"
                alt="logo"
                width="30"
                height="auto"
                className="d-inline-block align-text-top m-2"
              />
            </div>

            <a href={item.live}>{item.live.length > 1 ? item.name : ''}</a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Projects
