import React from 'react'
import { FaBootstrap } from 'react-icons/fa'
import resume from '../assets/ali_resume.pdf'
import RotateMan from './icons/RotateMan'
import mui from '../assets/mui.svg'
const About = () => {
  return (
    <div className="container-fluid bg-dark pt-5 d-flex flex-column align-items-center min-vh-100">
      {/*       <RotateMan />
       */}{' '}
      <p className="text-center lead m-4 introText">
        I am a Frontend Developer with a robust background in software
        development and user interface design. With hands-on experience in
        technologies like ReactJS, React Native, and JS, I have successfully
        developed and optimized web applications for global container shipping
        companies and leading home appliances brands. My expertise extends to
        implementing cutting-edge features and ensuring seamless user
        experiences on diverse platforms.
      </p>
      <a
        href={resume}
        download
        className="btn btn-primary mt-5 custom-btn fw-bold"
      >
        Download My Resume
      </a>
      <h3
        className="pt-5 pb-2 custom-color introText"
        style={{ color: 'whitesmoke' }}
      >
        Languages and tools I use
      </h3>
      <p className="lead">
        <img
          className="me-2"
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original-wordmark.svg"
          alt="html5"
          width="40"
          height="40"
        />
        <img
          className="m-2"
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original-wordmark.svg"
          alt="css3"
          width="40"
          height="40"
        />
        <img
          className="m-2"
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg"
          alt="javascript"
          width="30"
          height="35"
        />
        <img
          className="m-2"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg"
          alt="react"
          width="35"
          height="35"
        />

        <img
          className="m-2"
          src="https://cdn.cdnlogo.com/logos/g/31/github.svg"
          alt="git"
          width="40"
          height="40"
        />

        <img
          className="m-2"
          src="https://cdn.cdnlogo.com/logos/s/14/socket-io.svg"
          alt="git"
          width="35"
          height="35"
        />
        <img className="m-2" src={mui} alt="git" width="35" height="35" />
        <FaBootstrap
          className="m-2"
          style={{ width: '35', height: '35', color: '#7a14f5' }}
        />

        <img
          className="m-2"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg"
          alt="git"
          width="35"
          height="35"
        />
      </p>
    </div>
  )
}

export default About
